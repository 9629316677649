/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import Layout from 'components/Layout'
import Slider from 'components/slider'
import VideoHeroLocal from 'components/VideoHeroLocal/VideoHeroLocal'
import SideNavSection from 'components/sections/sideNav'
import SideNavigation from 'components/sections/sideNav/Navigation'
import Modal from 'components/Modal'

import HeadingArrowSvg from 'images/headingArrowSvg'
import ImageVideoPlayIconSvg from 'images/videoPlayIconSvg'
import heroVideo from 'videos/x-series/pennylane-trim_compressed.mp4'
import heroVideoThumbnail from 'videos/thumbnails/pennylane-thumbnail.jpeg'
import demosArray from 'data/demos/demosArray'
import Seo from '../../components/Seo'
import { sideNavData } from 'types'

const SideNavMenu = () => {
  const sideNavInfo: sideNavData = {
    title: 'Navigation',
    links: [
      { title: 'PennyLane', id: '#intro' },
      { title: 'Key Features', id: '#key-features' },
      { title: 'Getting Started', id: '#getting-started' },
      { title: 'Demos', id: '#demos' },
      { title: 'Documentation', id: '#documentation' },
    ],
  }

  return <SideNavigation sideNavInfo={sideNavInfo} />
}

const SideNavMain = () => {
  return (
    <>
      <div id="intro" className="jumptarget">
        <h3>PennyLane</h3>
        <p className="mt-8.5">
          <a href="https://pennylane.ai" target="_blank" rel="noopener">
            PennyLane
          </a>{' '}
          is the leading tool for programming quantum computers. A
          cross-platform Python library, it enables a new paradigm{' '}
          <span>&#8212;</span> quantum differentiable programming{' '}
          <span>&#8212;</span> that enables seamless integration with machine
          learning tools. Train a quantum computer like you would train a neural
          network. PennyLane also supports a comprehensive set of features,
          simulators, hardware, and community-led resources that enable users of
          all levels to easily build, optimize and deploy quantum-classical
          applications.
        </p>
        <hr />
      </div>
      <div id="key-features" className="jumptarget">
        <h3>Key Features</h3>
        <ul className="checkbox">
          <li>
            <strong>Write once, run anywhere.</strong>
            <br />
            Change your quantum device in a single line to swap between
            simulators and hardware; no other changes to your program needed.
          </li>
          <li>
            <strong>Simulators and hardware, all in one place.</strong>
            <br />
            Access the fastest all-purpose simulators and the widest hardware
            availability. Seamlessly combine high-performance compute and GPUs
            with quantum hardware from Xanadu, Amazon Braket, Google, IBM,
            Rigetti and more; move from rapid iteration to hardware testing with
            ease.
          </li>
          <li>
            <strong>A global community.</strong>
            <br />
            From our curated collection of tutorials, support forum,
            demonstrations, and videos, the PennyLane community is the place to
            go to learn quantum computing and quantum machine learning.
          </li>
          <li>
            <strong>
              Built-in automatic differentiation of quantum circuits.
            </strong>
            <br />
            PennyLane knows how to differentiate through all quantum devices,
            whether simulators or hardware. And it automatically chooses the
            best algorithms for the job.
          </li>
          <li>
            <strong>Machine learning on quantum hardware.</strong>
            <br />
            Connect quantum hardware seamlessly to PyTorch, TensorFlow, JAX, and
            NumPy to build rich and flexible quantum-classical models.
          </li>
          <li>
            <strong>Everything included.</strong>
            <br />
            PennyLane&apos;s core tenet is flexibility. Build the algorithms{' '}
            <strong>you </strong>
            envision <span>&#8212;</span> we won&apos;t get in your way. But
            when you need those extra tools, they are there, from quantum
            optimizers to quantum chemistry algorithms.
          </li>
        </ul>
      </div>
      <div className="standard-section-top-margin">
        <div id="getting-started" className="jumptarget">
          <h3>Getting Started</h3>

          <p className="mt-5">
            Install{' '}
            <a
              href="https://pennylane.ai/install/"
              target="_blank"
              rel="noopener"
            >
              PennyLane
            </a>{' '}
            on your computer locally.
          </p>
          <p className="mt-5">
            For an introduction to quantum machine learning, guides and
            resources are available on PennyLane&apos;s quantum machine learning
            hub:
          </p>
          <ul className="checkbox">
            <li>
              <a
                href="https://pennylane.ai/qml/whatisqml/"
                target="_blank"
                rel="noopener"
              >
                What is quantum machine learning?
              </a>
            </li>
            <li>
              <a
                href="https://pennylane.ai/qml/demonstrations/"
                target="_blank"
                rel="noopener"
              >
                Applications, tutorials, and demonstrations
              </a>
            </li>
            <li>
              <a
                href="https://pennylane.ai/faq/"
                target="_blank"
                rel="noopener"
              >
                Frequently asked questions
              </a>
            </li>
          </ul>
          <p className="mt-5">
            You can also check out our documentation for{' '}
            <a
              href="https://pennylane.readthedocs.io/en/stable/introduction/pennylane.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              quickstart guides
            </a>{' '}
            to using PennyLane, and detailed{' '}
            <a
              href="https://pennylane.readthedocs.io/en/stable/development/guide.html"
              target="_blank"
              rel="noreferrer"
            >
              developer guides
            </a>
            .
          </p>
        </div>
      </div>
      {/* DEMO */}
      <div className="standard-section-top-margin">
        <div id="demos" className="jumptarget">
          <h3>
            <a
              href="https://pennylane.ai/qml/demonstrations/"
              target="_blank"
              className="arrow-swipe-container"
              rel="noopener"
            >
              Demos
              <span className="arrow-swipe ml-3.75">
                <span>
                  <span>
                    <HeadingArrowSvg svgClassName="inline-block" />
                  </span>
                </span>
              </span>
            </a>
          </h3>
          <p className="mt-10">
            Get familiar with more advanced applications of PennyLane and
            quantum machine learning. Learn how to implement a variational
            quantum eigensolver, play around with quantum chemistry simulations,
            solve graph problems such as MaxCut, or implement quantum machine
            learning circuits on real quantum hardware.
          </p>
          <div className="mt-10">
            <Slider demos={demosArray} />
          </div>
        </div>
      </div>
      {/* DOCS */}
      <div className="standard-section-top-margin">
        <div id="documentation" className="jumptarget">
          <h3>Documentation</h3>
          <p className="mt-10">
            Access the{' '}
            <a
              href="https://pennylane.readthedocs.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              full documentation
            </a>
            , or read more in our PennyLane{' '}
            <a
              href="https://arxiv.org/abs/1811.04968"
              target="_blank"
              rel="noopener noreferrer"
            >
              paper
            </a>{' '}
            and differentiable quantum chemistry{' '}
            <a
              href="https://arxiv.org/abs/2111.09967"
              target="_blank"
              rel="noopener noreferrer"
            >
              paper
            </a>
            .
          </p>
        </div>
      </div>
    </>
  )
}

const PennyLanePage = () => {
  return (
    <Layout>
      <div className="mb-15">
        <VideoHeroLocal
          copyElement={
            <>
              <p className="text-white subtitle opacity-70">// PennyLane</p>
              <h1>Software library for programming quantum computers</h1>
              <div className="mt-5.5">
                <Modal
                  interaction={
                    <div className="inline watch-video">
                      <ImageVideoPlayIconSvg
                        circleColor="text-white"
                        widthAndHeight={30}
                      />
                      <span className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white">
                        <span data-hover="Watch Video">Watch Video</span>
                      </span>
                    </div>
                  }
                  title={'Welcome to PennyLane'}
                  content={
                    <div className="video-wrapper">
                      <iframe
                        src="https://www.youtube.com/embed/WOLzqeuXVT8?autoplay=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  }
                />
              </div>
            </>
          }
          video={heroVideo as string}
          thumbnail={heroVideoThumbnail as string}
        />
      </div>

      <SideNavSection nav={<SideNavMenu />} main={<SideNavMain />} />
    </Layout>
  )
}

export default PennyLanePage

export const Head = () => {
  return (
    <Seo
      title="PennyLane"
      description="Quantum differentiable programming on any quantum computing platform"
    />
  )
}
